<template>
    <SmartLinkItemComponent custom-tag="div" class="split-content-block-image" v-if="model" :id="`_${model.system.id}`"
        :component="!model.system.workflow">
        <SmartLinkStandaloneElement :codename="type.elements.image.codename">
            <NuxtImg class="img-fluid" provider="kontentAiProvider" v-if="model?.elements?.image?.value"
                :src="firstOrDefault(model.elements.image.value)?.url" />
            <div class="img-caption" v-if="model.elements.caption.value">{{ model.elements.caption.value }}</div>
        </SmartLinkStandaloneElement>
    </SmartLinkItemComponent>
</template>

<script lang="ts" setup>
import { type SplitContentBlockImageModel, contentTypes } from '~/models';
const type = contentTypes.split_content_block_image;
const props = defineProps<{ model: SplitContentBlockImageModel }>();
</script>